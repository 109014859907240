@import "https://fonts.googleapis.com/css?family=Montserrat:400,900";
html {
  min-height: 100%;
}

body {
  height: 100vh;
  min-height: 100%;
  overscroll-behavior-y: none;
  background-color: #bce49f;
  flex-direction: column;
  margin: 0;
  padding: 0;
  display: flex;
  overflow: hidden;
}

body * {
  color: #fff;
  font-family: Montserrat, sans-serif;
}

#tfft-countdown nav {
  z-index: 10;
  position: fixed;
}

#tfft-countdown nav ul {
  background-color: #0000;
  margin: 0;
  padding: 0;
  list-style: none;
}

#tfft-countdown nav ul li a, #tfft-countdown nav ul li a:visited {
  background-color: #000;
  border-bottom-right-radius: .75rem;
  padding: .55rem;
  font-size: .75rem;
  text-decoration: none;
  display: block;
  box-shadow: 0 .15em .23em #00000080;
}

.TfftCountdown {
  width: 100%;
  height: 100%;
  flex-direction: column;
  display: flex;
  overflow-y: auto;
}

@media only screen and (min-width: 550px) {
  .TfftCountdown {
    flex-direction: row;
  }
}

.TfftCountdown .group {
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.TfftCountdown .group.bg-0:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-0:before {
    width: 0%;
    height: 25%;
    right: 100%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-0:before {
    height: 0%;
    width: 25%;
    top: 100%;
  }
}

.TfftCountdown .group.bg-1:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-1:before {
    width: 1%;
    height: 25%;
    right: 99%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-1:before {
    height: 1%;
    width: 25%;
    top: 99%;
  }
}

.TfftCountdown .group.bg-2:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-2:before {
    width: 2%;
    height: 25%;
    right: 98%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-2:before {
    height: 2%;
    width: 25%;
    top: 98%;
  }
}

.TfftCountdown .group.bg-3:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-3:before {
    width: 3%;
    height: 25%;
    right: 97%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-3:before {
    height: 3%;
    width: 25%;
    top: 97%;
  }
}

.TfftCountdown .group.bg-4:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-4:before {
    width: 4%;
    height: 25%;
    right: 96%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-4:before {
    height: 4%;
    width: 25%;
    top: 96%;
  }
}

.TfftCountdown .group.bg-5:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-5:before {
    width: 5%;
    height: 25%;
    right: 95%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-5:before {
    height: 5%;
    width: 25%;
    top: 95%;
  }
}

.TfftCountdown .group.bg-6:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-6:before {
    width: 6%;
    height: 25%;
    right: 94%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-6:before {
    height: 6%;
    width: 25%;
    top: 94%;
  }
}

.TfftCountdown .group.bg-7:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-7:before {
    width: 7%;
    height: 25%;
    right: 93%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-7:before {
    height: 7%;
    width: 25%;
    top: 93%;
  }
}

.TfftCountdown .group.bg-8:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-8:before {
    width: 8%;
    height: 25%;
    right: 92%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-8:before {
    height: 8%;
    width: 25%;
    top: 92%;
  }
}

.TfftCountdown .group.bg-9:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-9:before {
    width: 9%;
    height: 25%;
    right: 91%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-9:before {
    height: 9%;
    width: 25%;
    top: 91%;
  }
}

.TfftCountdown .group.bg-10:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-10:before {
    width: 10%;
    height: 25%;
    right: 90%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-10:before {
    height: 10%;
    width: 25%;
    top: 90%;
  }
}

.TfftCountdown .group.bg-11:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-11:before {
    width: 11%;
    height: 25%;
    right: 89%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-11:before {
    height: 11%;
    width: 25%;
    top: 89%;
  }
}

.TfftCountdown .group.bg-12:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-12:before {
    width: 12%;
    height: 25%;
    right: 88%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-12:before {
    height: 12%;
    width: 25%;
    top: 88%;
  }
}

.TfftCountdown .group.bg-13:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-13:before {
    width: 13%;
    height: 25%;
    right: 87%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-13:before {
    height: 13%;
    width: 25%;
    top: 87%;
  }
}

.TfftCountdown .group.bg-14:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-14:before {
    width: 14%;
    height: 25%;
    right: 86%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-14:before {
    height: 14%;
    width: 25%;
    top: 86%;
  }
}

.TfftCountdown .group.bg-15:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-15:before {
    width: 15%;
    height: 25%;
    right: 85%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-15:before {
    height: 15%;
    width: 25%;
    top: 85%;
  }
}

.TfftCountdown .group.bg-16:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-16:before {
    width: 16%;
    height: 25%;
    right: 84%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-16:before {
    height: 16%;
    width: 25%;
    top: 84%;
  }
}

.TfftCountdown .group.bg-17:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-17:before {
    width: 17%;
    height: 25%;
    right: 83%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-17:before {
    height: 17%;
    width: 25%;
    top: 83%;
  }
}

.TfftCountdown .group.bg-18:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-18:before {
    width: 18%;
    height: 25%;
    right: 82%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-18:before {
    height: 18%;
    width: 25%;
    top: 82%;
  }
}

.TfftCountdown .group.bg-19:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-19:before {
    width: 19%;
    height: 25%;
    right: 81%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-19:before {
    height: 19%;
    width: 25%;
    top: 81%;
  }
}

.TfftCountdown .group.bg-20:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-20:before {
    width: 20%;
    height: 25%;
    right: 80%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-20:before {
    height: 20%;
    width: 25%;
    top: 80%;
  }
}

.TfftCountdown .group.bg-21:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-21:before {
    width: 21%;
    height: 25%;
    right: 79%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-21:before {
    height: 21%;
    width: 25%;
    top: 79%;
  }
}

.TfftCountdown .group.bg-22:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-22:before {
    width: 22%;
    height: 25%;
    right: 78%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-22:before {
    height: 22%;
    width: 25%;
    top: 78%;
  }
}

.TfftCountdown .group.bg-23:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-23:before {
    width: 23%;
    height: 25%;
    right: 77%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-23:before {
    height: 23%;
    width: 25%;
    top: 77%;
  }
}

.TfftCountdown .group.bg-24:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-24:before {
    width: 24%;
    height: 25%;
    right: 76%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-24:before {
    height: 24%;
    width: 25%;
    top: 76%;
  }
}

.TfftCountdown .group.bg-25:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-25:before {
    width: 25%;
    height: 25%;
    right: 75%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-25:before {
    height: 25%;
    width: 25%;
    top: 75%;
  }
}

.TfftCountdown .group.bg-26:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-26:before {
    width: 26%;
    height: 25%;
    right: 74%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-26:before {
    height: 26%;
    width: 25%;
    top: 74%;
  }
}

.TfftCountdown .group.bg-27:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-27:before {
    width: 27%;
    height: 25%;
    right: 73%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-27:before {
    height: 27%;
    width: 25%;
    top: 73%;
  }
}

.TfftCountdown .group.bg-28:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-28:before {
    width: 28%;
    height: 25%;
    right: 72%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-28:before {
    height: 28%;
    width: 25%;
    top: 72%;
  }
}

.TfftCountdown .group.bg-29:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-29:before {
    width: 29%;
    height: 25%;
    right: 71%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-29:before {
    height: 29%;
    width: 25%;
    top: 71%;
  }
}

.TfftCountdown .group.bg-30:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-30:before {
    width: 30%;
    height: 25%;
    right: 70%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-30:before {
    height: 30%;
    width: 25%;
    top: 70%;
  }
}

.TfftCountdown .group.bg-31:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-31:before {
    width: 31%;
    height: 25%;
    right: 69%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-31:before {
    height: 31%;
    width: 25%;
    top: 69%;
  }
}

.TfftCountdown .group.bg-32:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-32:before {
    width: 32%;
    height: 25%;
    right: 68%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-32:before {
    height: 32%;
    width: 25%;
    top: 68%;
  }
}

.TfftCountdown .group.bg-33:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-33:before {
    width: 33%;
    height: 25%;
    right: 67%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-33:before {
    height: 33%;
    width: 25%;
    top: 67%;
  }
}

.TfftCountdown .group.bg-34:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-34:before {
    width: 34%;
    height: 25%;
    right: 66%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-34:before {
    height: 34%;
    width: 25%;
    top: 66%;
  }
}

.TfftCountdown .group.bg-35:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-35:before {
    width: 35%;
    height: 25%;
    right: 65%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-35:before {
    height: 35%;
    width: 25%;
    top: 65%;
  }
}

.TfftCountdown .group.bg-36:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-36:before {
    width: 36%;
    height: 25%;
    right: 64%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-36:before {
    height: 36%;
    width: 25%;
    top: 64%;
  }
}

.TfftCountdown .group.bg-37:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-37:before {
    width: 37%;
    height: 25%;
    right: 63%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-37:before {
    height: 37%;
    width: 25%;
    top: 63%;
  }
}

.TfftCountdown .group.bg-38:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-38:before {
    width: 38%;
    height: 25%;
    right: 62%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-38:before {
    height: 38%;
    width: 25%;
    top: 62%;
  }
}

.TfftCountdown .group.bg-39:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-39:before {
    width: 39%;
    height: 25%;
    right: 61%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-39:before {
    height: 39%;
    width: 25%;
    top: 61%;
  }
}

.TfftCountdown .group.bg-40:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-40:before {
    width: 40%;
    height: 25%;
    right: 60%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-40:before {
    height: 40%;
    width: 25%;
    top: 60%;
  }
}

.TfftCountdown .group.bg-41:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-41:before {
    width: 41%;
    height: 25%;
    right: 59%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-41:before {
    height: 41%;
    width: 25%;
    top: 59%;
  }
}

.TfftCountdown .group.bg-42:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-42:before {
    width: 42%;
    height: 25%;
    right: 58%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-42:before {
    height: 42%;
    width: 25%;
    top: 58%;
  }
}

.TfftCountdown .group.bg-43:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-43:before {
    width: 43%;
    height: 25%;
    right: 57%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-43:before {
    height: 43%;
    width: 25%;
    top: 57%;
  }
}

.TfftCountdown .group.bg-44:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-44:before {
    width: 44%;
    height: 25%;
    right: 56%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-44:before {
    height: 44%;
    width: 25%;
    top: 56%;
  }
}

.TfftCountdown .group.bg-45:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-45:before {
    width: 45%;
    height: 25%;
    right: 55%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-45:before {
    height: 45%;
    width: 25%;
    top: 55%;
  }
}

.TfftCountdown .group.bg-46:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-46:before {
    width: 46%;
    height: 25%;
    right: 54%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-46:before {
    height: 46%;
    width: 25%;
    top: 54%;
  }
}

.TfftCountdown .group.bg-47:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-47:before {
    width: 47%;
    height: 25%;
    right: 53%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-47:before {
    height: 47%;
    width: 25%;
    top: 53%;
  }
}

.TfftCountdown .group.bg-48:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-48:before {
    width: 48%;
    height: 25%;
    right: 52%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-48:before {
    height: 48%;
    width: 25%;
    top: 52%;
  }
}

.TfftCountdown .group.bg-49:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-49:before {
    width: 49%;
    height: 25%;
    right: 51%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-49:before {
    height: 49%;
    width: 25%;
    top: 51%;
  }
}

.TfftCountdown .group.bg-50:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-50:before {
    width: 50%;
    height: 25%;
    right: 50%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-50:before {
    height: 50%;
    width: 25%;
    top: 50%;
  }
}

.TfftCountdown .group.bg-51:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-51:before {
    width: 51%;
    height: 25%;
    right: 49%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-51:before {
    height: 51%;
    width: 25%;
    top: 49%;
  }
}

.TfftCountdown .group.bg-52:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-52:before {
    width: 52%;
    height: 25%;
    right: 48%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-52:before {
    height: 52%;
    width: 25%;
    top: 48%;
  }
}

.TfftCountdown .group.bg-53:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-53:before {
    width: 53%;
    height: 25%;
    right: 47%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-53:before {
    height: 53%;
    width: 25%;
    top: 47%;
  }
}

.TfftCountdown .group.bg-54:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-54:before {
    width: 54%;
    height: 25%;
    right: 46%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-54:before {
    height: 54%;
    width: 25%;
    top: 46%;
  }
}

.TfftCountdown .group.bg-55:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-55:before {
    width: 55%;
    height: 25%;
    right: 45%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-55:before {
    height: 55%;
    width: 25%;
    top: 45%;
  }
}

.TfftCountdown .group.bg-56:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-56:before {
    width: 56%;
    height: 25%;
    right: 44%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-56:before {
    height: 56%;
    width: 25%;
    top: 44%;
  }
}

.TfftCountdown .group.bg-57:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-57:before {
    width: 57%;
    height: 25%;
    right: 43%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-57:before {
    height: 57%;
    width: 25%;
    top: 43%;
  }
}

.TfftCountdown .group.bg-58:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-58:before {
    width: 58%;
    height: 25%;
    right: 42%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-58:before {
    height: 58%;
    width: 25%;
    top: 42%;
  }
}

.TfftCountdown .group.bg-59:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-59:before {
    width: 59%;
    height: 25%;
    right: 41%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-59:before {
    height: 59%;
    width: 25%;
    top: 41%;
  }
}

.TfftCountdown .group.bg-60:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-60:before {
    width: 60%;
    height: 25%;
    right: 40%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-60:before {
    height: 60%;
    width: 25%;
    top: 40%;
  }
}

.TfftCountdown .group.bg-61:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-61:before {
    width: 61%;
    height: 25%;
    right: 39%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-61:before {
    height: 61%;
    width: 25%;
    top: 39%;
  }
}

.TfftCountdown .group.bg-62:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-62:before {
    width: 62%;
    height: 25%;
    right: 38%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-62:before {
    height: 62%;
    width: 25%;
    top: 38%;
  }
}

.TfftCountdown .group.bg-63:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-63:before {
    width: 63%;
    height: 25%;
    right: 37%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-63:before {
    height: 63%;
    width: 25%;
    top: 37%;
  }
}

.TfftCountdown .group.bg-64:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-64:before {
    width: 64%;
    height: 25%;
    right: 36%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-64:before {
    height: 64%;
    width: 25%;
    top: 36%;
  }
}

.TfftCountdown .group.bg-65:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-65:before {
    width: 65%;
    height: 25%;
    right: 35%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-65:before {
    height: 65%;
    width: 25%;
    top: 35%;
  }
}

.TfftCountdown .group.bg-66:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-66:before {
    width: 66%;
    height: 25%;
    right: 34%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-66:before {
    height: 66%;
    width: 25%;
    top: 34%;
  }
}

.TfftCountdown .group.bg-67:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-67:before {
    width: 67%;
    height: 25%;
    right: 33%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-67:before {
    height: 67%;
    width: 25%;
    top: 33%;
  }
}

.TfftCountdown .group.bg-68:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-68:before {
    width: 68%;
    height: 25%;
    right: 32%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-68:before {
    height: 68%;
    width: 25%;
    top: 32%;
  }
}

.TfftCountdown .group.bg-69:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-69:before {
    width: 69%;
    height: 25%;
    right: 31%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-69:before {
    height: 69%;
    width: 25%;
    top: 31%;
  }
}

.TfftCountdown .group.bg-70:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-70:before {
    width: 70%;
    height: 25%;
    right: 30%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-70:before {
    height: 70%;
    width: 25%;
    top: 30%;
  }
}

.TfftCountdown .group.bg-71:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-71:before {
    width: 71%;
    height: 25%;
    right: 29%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-71:before {
    height: 71%;
    width: 25%;
    top: 29%;
  }
}

.TfftCountdown .group.bg-72:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-72:before {
    width: 72%;
    height: 25%;
    right: 28%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-72:before {
    height: 72%;
    width: 25%;
    top: 28%;
  }
}

.TfftCountdown .group.bg-73:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-73:before {
    width: 73%;
    height: 25%;
    right: 27%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-73:before {
    height: 73%;
    width: 25%;
    top: 27%;
  }
}

.TfftCountdown .group.bg-74:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-74:before {
    width: 74%;
    height: 25%;
    right: 26%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-74:before {
    height: 74%;
    width: 25%;
    top: 26%;
  }
}

.TfftCountdown .group.bg-75:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-75:before {
    width: 75%;
    height: 25%;
    right: 25%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-75:before {
    height: 75%;
    width: 25%;
    top: 25%;
  }
}

.TfftCountdown .group.bg-76:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-76:before {
    width: 76%;
    height: 25%;
    right: 24%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-76:before {
    height: 76%;
    width: 25%;
    top: 24%;
  }
}

.TfftCountdown .group.bg-77:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-77:before {
    width: 77%;
    height: 25%;
    right: 23%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-77:before {
    height: 77%;
    width: 25%;
    top: 23%;
  }
}

.TfftCountdown .group.bg-78:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-78:before {
    width: 78%;
    height: 25%;
    right: 22%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-78:before {
    height: 78%;
    width: 25%;
    top: 22%;
  }
}

.TfftCountdown .group.bg-79:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-79:before {
    width: 79%;
    height: 25%;
    right: 21%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-79:before {
    height: 79%;
    width: 25%;
    top: 21%;
  }
}

.TfftCountdown .group.bg-80:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-80:before {
    width: 80%;
    height: 25%;
    right: 20%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-80:before {
    height: 80%;
    width: 25%;
    top: 20%;
  }
}

.TfftCountdown .group.bg-81:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-81:before {
    width: 81%;
    height: 25%;
    right: 19%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-81:before {
    height: 81%;
    width: 25%;
    top: 19%;
  }
}

.TfftCountdown .group.bg-82:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-82:before {
    width: 82%;
    height: 25%;
    right: 18%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-82:before {
    height: 82%;
    width: 25%;
    top: 18%;
  }
}

.TfftCountdown .group.bg-83:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-83:before {
    width: 83%;
    height: 25%;
    right: 17%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-83:before {
    height: 83%;
    width: 25%;
    top: 17%;
  }
}

.TfftCountdown .group.bg-84:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-84:before {
    width: 84%;
    height: 25%;
    right: 16%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-84:before {
    height: 84%;
    width: 25%;
    top: 16%;
  }
}

.TfftCountdown .group.bg-85:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-85:before {
    width: 85%;
    height: 25%;
    right: 15%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-85:before {
    height: 85%;
    width: 25%;
    top: 15%;
  }
}

.TfftCountdown .group.bg-86:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-86:before {
    width: 86%;
    height: 25%;
    right: 14%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-86:before {
    height: 86%;
    width: 25%;
    top: 14%;
  }
}

.TfftCountdown .group.bg-87:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-87:before {
    width: 87%;
    height: 25%;
    right: 13%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-87:before {
    height: 87%;
    width: 25%;
    top: 13%;
  }
}

.TfftCountdown .group.bg-88:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-88:before {
    width: 88%;
    height: 25%;
    right: 12%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-88:before {
    height: 88%;
    width: 25%;
    top: 12%;
  }
}

.TfftCountdown .group.bg-89:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-89:before {
    width: 89%;
    height: 25%;
    right: 11%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-89:before {
    height: 89%;
    width: 25%;
    top: 11%;
  }
}

.TfftCountdown .group.bg-90:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-90:before {
    width: 90%;
    height: 25%;
    right: 10%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-90:before {
    height: 90%;
    width: 25%;
    top: 10%;
  }
}

.TfftCountdown .group.bg-91:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-91:before {
    width: 91%;
    height: 25%;
    right: 9%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-91:before {
    height: 91%;
    width: 25%;
    top: 9%;
  }
}

.TfftCountdown .group.bg-92:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-92:before {
    width: 92%;
    height: 25%;
    right: 8%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-92:before {
    height: 92%;
    width: 25%;
    top: 8%;
  }
}

.TfftCountdown .group.bg-93:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-93:before {
    width: 93%;
    height: 25%;
    right: 7%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-93:before {
    height: 93%;
    width: 25%;
    top: 7%;
  }
}

.TfftCountdown .group.bg-94:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-94:before {
    width: 94%;
    height: 25%;
    right: 6%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-94:before {
    height: 94%;
    width: 25%;
    top: 6%;
  }
}

.TfftCountdown .group.bg-95:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-95:before {
    width: 95%;
    height: 25%;
    right: 5%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-95:before {
    height: 95%;
    width: 25%;
    top: 5%;
  }
}

.TfftCountdown .group.bg-96:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-96:before {
    width: 96%;
    height: 25%;
    right: 4%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-96:before {
    height: 96%;
    width: 25%;
    top: 4%;
  }
}

.TfftCountdown .group.bg-97:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-97:before {
    width: 97%;
    height: 25%;
    right: 3%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-97:before {
    height: 97%;
    width: 25%;
    top: 3%;
  }
}

.TfftCountdown .group.bg-98:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-98:before {
    width: 98%;
    height: 25%;
    right: 2%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-98:before {
    height: 98%;
    width: 25%;
    top: 2%;
  }
}

.TfftCountdown .group.bg-99:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-99:before {
    width: 99%;
    height: 25%;
    right: 1%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-99:before {
    height: 99%;
    width: 25%;
    top: 1%;
  }
}

.TfftCountdown .group.bg-100:before {
  content: "";
  background-color: #fff3;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .TfftCountdown .group.bg-100:before {
    width: 100%;
    height: 25%;
    right: 0%;
  }
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group.bg-100:before {
    height: 100%;
    width: 25%;
    top: 0%;
  }
}

.TfftCountdown .group .digit {
  letter-spacing: .05em;
  font-size: 2.5em;
  font-weight: 900;
  line-height: 3rem;
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group .digit {
    letter-spacing: .05em;
    font-size: 2.7em;
    line-height: 3rem;
  }
}

@media only screen and (min-width: 920px) {
  .TfftCountdown .group .digit {
    letter-spacing: .05em;
    font-size: 5em;
    line-height: 6rem;
  }
}

.TfftCountdown .group .label {
  text-transform: uppercase;
  font-size: 1.8em;
  font-weight: 400;
  line-height: 1.5rem;
}

@media only screen and (min-width: 550px) {
  .TfftCountdown .group .label {
    letter-spacing: .05em;
    font-size: 1.3em;
    line-height: 1.1rem;
  }
}

@media only screen and (min-width: 920px) {
  .TfftCountdown .group .label {
    letter-spacing: .05em;
    font-size: 2em;
  }
}

.TfftCountdown .group:nth-child(1) {
  background-color: #014e92;
}

.TfftCountdown .group:nth-child(2) {
  background-color: #035eae;
}

.TfftCountdown .group:nth-child(3) {
  background-color: #186db8;
}

.TfftCountdown .group:nth-child(4) {
  background-color: #2d76b6;
}

/*# sourceMappingURL=index.102160e1.css.map */
